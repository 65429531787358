<template>
  <div>
    <page-header :title="$t('paths.help')" />

    <h1>Conneting Timer And Ticket tools</h1>
    <p>
      With Timer2Ticket app, you van integrate Ticket (project) tool (Jira,
      Redmine) and time tracking tools (Toggl Track). Timer2Ticket will than
      perform configuration objects (issues, projects) and time entries
      synchronization.
    </p>
    <p>
      Issues and projects will be copyed from your project tool and corespondig
      object will be created in the time tracking tool. You can than select
      coresponding issue or project in tracking tool to record time entry. Time
      entry will than be synchronized with the project tool.
    </p>
    <h2>Setting up the connection</h2>
    <p>
      User is required to provide api keys and some other access information so
      Timer2Ticket is able to read and create data. The needed acces key can be
      found in following locations. Fill the form and save the Connection.
    </p>
    <ul>
      <li>Toggl Track</li>
      <ul>
        <li>
          API Key: "Profile" > "Profile settings" in the bottem left corner,
          scroll down the page and click "Reveal" to see the API Key. Or follow
          this
          <a
            :href="TogglTrackAPIKeyLink"
            target="_blank"
            rel="noopener noreferrer"
            >link</a
          >.
        </li>
      </ul>
      <li>Redmine</li>
      <ul>
        <li>
          API Key: "My account" in the top right corner. Click "Show" in the
          right menu to reveal API key.
        </li>
        <li>
          API Point: Url of you Redmine. For exmaple: "redmine.mycompany.com"
        </li>
      </ul>
      <li>Jira</li>
      <ul>
        <li>
          Jira Domain: Url of you Jira. For exmaple: "myJira.atlassian.net"
        </li>
        <li>
          API Key: "Account settings" in the top right corner, then "Manage
          account". Select "Security" and "Create and Manage API tokens". Create
          new API Token. Or follow this
          <a :href="jiraAPIKeyLink" target="_blank" rel="noopener noreferrer"
            >link</a
          >.
        </li>
        <li>Jira email adress: Email you use to log into Jira"</li>
        <li>
          Fallback issue: Time entries without assigned issue will be
          synchronized with this issue. It will be either found or created by
          T2T. Issue name can be changed later.
        </li>
      </ul>
    </ul>

    <h1>Conneting two projects tools</h1>
    <h2>Setting up the connection</h2>
    <p>
      First you need to fill forms with acces data the same way as described
      above. Then you will be asked to select custom fields, which will be used
      to map issues. After selecting custom fields, projects containg the custom
      field will be shown on the screen. Create pairs of projects, where Time
      entries should be synchronised. Save the Connection.
    </p>
    <h2>How synchronization works</h2>
    <p>
      Custom field is used by Timer2Ticket to identify issue pairs within
      project pairs. You can create isse pair by copying link of the issue and
      pasting it into the mapping custom field within the issie in the second
      tool. Timer2Ticket then creates pair of these two isses. Once pair is
      created, it cannot be changed.
    </p>
    <p>
      You will be asked to create project pairs. within selected projects,
      issues (tasks) will be mapped based on selected cutom field. After pair is
      establihed, time entries will be synchronized both ways. Non additional
      issues or projects will be created in neither of the tools.
    </p>
    <h1>Setting up a webhook</h1>
    <h2>Jira webhooks</h2>
    <p>
      Go to "Synchronization schedule" within the connection you want to set up
      the webhook to. Copy the link Timer2Ticket provides and go to the Jira.
      Click settings icon in th etop rigth corner of your Jira and go to
      "System". Scroll down the left menu and select "WebHooks". Create new
      Webhook, paste the link provided by Timer2Ticket and select following
      events. save the webhook.
    </p>
    <ul>
      <li>Issue</li>
      <ul>
        <li>created</li>
        <li>updated</li>
        <li>deleted</li>
      </ul>
      <li>Worklog</li>
      <ul>
        <li>created</li>
        <li>updated</li>
        <li>deleted</li>
      </ul>
      <li>Project</li>
      <ul>
        <li>created</li>
        <li>updated</li>
        <li>deleted</li>
      </ul>
    </ul>
    <h2>Toggl Track webhooks</h2>
    <p>
      You can enable webhooks for Toggl Track in the schedule configuration.
      Timer2Ticket will then create webhook in your Toggl Track itself. You can
      disable webhook whenever you like both in Timer2Ticket and Toggl Track.
    </p>
    <h2>Redmine webhooks</h2>
    <p>
      Redmine webhooks are not supported, since Redmine does not support
      webhooks.
    </p>
  </div>
</template>

<script>
export default {
  components: {
    "page-header": () => import("../components/PageHeader.vue"),
  },
  data() {
    return {
      jiraAPIKeyLink:
        "https://id.atlassian.com/manage-profile/security/api-tokens",
      TogglTrackAPIKeyLink: "https://track.toggl.com/profile",
    };
  },
};
</script>
